import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import apiWrapper from "../../../apiConfig";

const initialServerState = {
  name: "",
  ssh_host: "",
  ssh_user: "",
  ssh_password: "",
};

const Servers = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [error, setError] = useState("");
  const [dialogState, setDialogState] = useState({
    open: false,
    isEditing: false,
    serverData: initialServerState,
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchServers = useCallback(async () => {
    try {
      const { data } = await apiWrapper.get(`/api/servers`, {
        headers: { Authorization: token },
      });
      setServers(data.data);
    } catch {
      setError("Не удалось загрузить список серверов");
    }
  }, [token]);

  useEffect(() => {
    fetchServers();
  }, [fetchServers]);

  const handleDialogOpen = (isEditing = false, server = initialServerState) => {
    setDialogState({ open: true, isEditing, serverData: server });
  };

  const handleDialogClose = () => {
    setDialogState({ open: false, isEditing: false, serverData: initialServerState });
  };

  const handleServerChange = (field, value) => {
    setDialogState((prevState) => ({
      ...prevState,
      serverData: { ...prevState.serverData, [field]: value },
    }));
  };

  const handleServerSubmit = async () => {
    const { isEditing, serverData } = dialogState;
    try {
      if (isEditing) {
        await apiWrapper.put(
            `/api/servers/${serverData.id}`,
            serverData,
            { headers: { Authorization: token } }
        );
      } else {
        await apiWrapper.post(`/api/servers`, serverData, {
          headers: { Authorization: token },
        });
      }
      fetchServers();
      handleDialogClose();
    } catch {
      setError(isEditing ? "Не удалось обновить сервер" : "Не удалось добавить сервер");
    }
  };

  const handleDeleteServer = async (id) => {
    try {
      await apiWrapper.delete(`/api/servers/${id}`, {
        headers: { Authorization: token },
      });
      fetchServers();
    } catch {
      setError("Не удалось удалить сервер");
    }
  };

  const renderServerTable = () => (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>IP-адрес</TableCell>
              <TableCell>Пользователь</TableCell>
              <TableCell>Пароль</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servers.map((server) => (
                <TableRow key={server.id}>
                  <TableCell>{server.name}</TableCell>
                  <TableCell>{server.ssh_host}</TableCell>
                  <TableCell>{server.ssh_user}</TableCell>
                  <TableCell>{server.ssh_password}</TableCell>
                  <TableCell>
                    <IconButton
                        color="primary"
                        title="Редактировать сервер"
                        onClick={() => handleDialogOpen(true, server)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        title="Удалить сервер"
                        onClick={() => handleDeleteServer(server.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
  );

  const renderServerCards = () => (
      <Grid container spacing={2}>
        {servers.map((server) => (
            <Grid item xs={12} sm={6} md={4} key={server.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{server.name}</Typography>
                  <Typography variant="body2">IP: {server.ssh_host}</Typography>
                  <Typography variant="body2">Пользователь: {server.ssh_user}</Typography>
                  <Typography variant="body2">Пароль: {server.ssh_password}</Typography>
                </CardContent>
                <CardActions>
                  <IconButton
                      color="primary"
                      title="Редактировать сервер"
                      onClick={() => handleDialogOpen(true, server)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                      color="secondary"
                      title="Удалить сервер"
                      onClick={() => handleDeleteServer(server.id)}
                  >
                    <Delete />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
        ))}
      </Grid>
  );

  return (
      <Container>
        {error && <Typography color="error">{error}</Typography>}
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button variant="contained" color="primary" onClick={() => handleDialogOpen()}>
            Добавить сервер
          </Button>
        </Box>
        {isSmallScreen ? renderServerCards() : renderServerTable()}
        <Dialog open={dialogState.open} onClose={handleDialogClose}>
          <DialogTitle>{dialogState.isEditing ? "Редактировать сервер" : "Добавить сервер"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Заполните данные сервера:
            </DialogContentText>
            {Object.keys(initialServerState).map((field) => (
                <TextField
                    key={field}
                    margin="normal"
                    label={field.replace("_", " ").toUpperCase()}
                    value={dialogState.serverData[field]}
                    onChange={(e) => handleServerChange(field, e.target.value)}
                    fullWidth
                />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Отмена</Button>
            <Button onClick={handleServerSubmit} color="primary">
              {dialogState.isEditing ? "Сохранить" : "Добавить"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
};

export default Servers;