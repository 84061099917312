import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Paper,
  useTheme, useMediaQuery, Grid, Card, CardContent
} from '@mui/material';
import {
  Add,
  MoveUp,
  Edit,
  Delete,
  TransferWithinAStation,
  History
} from '@mui/icons-material';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import BackHandIcon from '@mui/icons-material/BackHand';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { differenceInHours, parseISO } from 'date-fns';
import apiWrapper from './../../../apiConfig';

// Диалоги для доменов
import DomainDialog from './../../dialogs/DomainDialog';
import DeleteDomainDialog from './../../dialogs/DeleteDomainDialog';
import DomainTransferDialog from './../../dialogs/DomainTransferDialog';
import DomainTransferHistoryDialog from './../../dialogs/DomainTransferHistoryDialog';
import DomainRedirectModal from './../../dialogs/DomainRedirectModal';
import PageRuleDialog from './../../dialogs/PageRuleDialog';
import ChangeDNSDialog from "../../dialogs/ChangeDNSDialog";

const ProjectDomains = ({ token, project, onProjectChanged, editProject, deleteProject }) => {
  // Список доменов, которые принадлежат проекту
  const [assignedDomains, setAssignedDomains] = useState([]);
  // Список резервных (unassigned) доменов
  const [unassignedDomains, setUnassignedDomains] = useState([]);

  // Фетчим сервера и Cloudflare-аккаунты для Add/Edit Domain
  const [servers, setServers] = useState([]);
  const [cloudflareAccounts, setCloudflareAccounts] = useState([]);

  // Выбранный домен (для любого действия: редактирование, удаление, DNS, редиректы и т.д.)
  const [domainInFocus, setDomainInFocus] = useState(null);

  // Диалоги
  const [domainDialogOpen, setDomainDialogOpen] = useState(false);  // Add / Edit domain
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [changeDNSOpen, setChangeDNSOpen] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [pageRuleOpen, setPageRuleOpen] = useState(false);

  // ---------------------------------------------------------------------------------
  //                             FETCH DOMAINS & SERVERS
  // ---------------------------------------------------------------------------------
  const fetchAssignedDomains = useCallback(async () => {
    try {
      const response = await apiWrapper.get(`/api/project_domains/${project.id}`, {
        headers: { Authorization: token }
      });
      setAssignedDomains(response.data.data);
    } catch (err) {
      console.error('Failed to fetch assigned domains:', err);
    }
  }, [project.id, token]);

  const fetchUnassignedDomains = useCallback(async () => {
    try {
      const response = await apiWrapper.get(
        `/api/project_unassigned_domains/${project.id}`,
        { headers: { Authorization: token } }
      );
      setUnassignedDomains(response.data.data);
    } catch (err) {
      console.error('Failed to fetch unassigned domains:', err);
    }
  }, [project.id, token]);

  const fetchServers = useCallback(async () => {
    try {
      const res = await apiWrapper.get(`/api/servers`, {
        headers: { Authorization: token }
      });
      setServers(res.data.data);
    } catch (err) {
      console.error('Failed to fetch servers:', err);
    }
  }, [token]);

  const fetchCloudflareAccounts = useCallback(async () => {
    try {
      const res = await apiWrapper.get(`/api/cloudflare_accounts`, {
        headers: { Authorization: token }
      });
      setCloudflareAccounts(res.data.data);
    } catch (err) {
      console.error('Failed to fetch cloudflare accounts:', err);
    }
  }, [token]);

  useEffect(() => {
    fetchAssignedDomains();
    fetchUnassignedDomains();
    fetchServers();
    fetchCloudflareAccounts();
  }, [
    fetchAssignedDomains,
    fetchUnassignedDomains,
    fetchServers,
    fetchCloudflareAccounts
  ]);

  // ---------------------------------------------------------------------------------
  //                         ADD / EDIT DOMAIN
  // ---------------------------------------------------------------------------------
  const handleOpenAddDomain = () => {
    setIsEditMode(false);
    setDomainInFocus({
      domain_name: '',
      file_path: '',
      type: 'main',
      transfer_type: 'auto',
      server_id: '',
      project_id: project.id
    });
    setDomainDialogOpen(true);
  };

  const handleOpenEditDomain = async (domain) => {
    setIsEditMode(true);
    setDomainInFocus(null);
    try {
      const res = await apiWrapper.get(`/api/domains/${domain.id}`, {
        headers: { Authorization: token }
      });
      setDomainInFocus(res.data.data);
      setDomainDialogOpen(true);
    } catch (err) {
      console.error('Failed to fetch domain details:', err);
    }
  };

  const handleSubmitDomain = async (domainData) => {
    if (!isEditMode) {
      // Создание нового домена
      try {
        await apiWrapper.post(
          `/api/domains`,
          { ...domainData, status: 'available' },
          { headers: { Authorization: token } }
        );
        setDomainDialogOpen(false);
        fetchAssignedDomains();
        fetchUnassignedDomains();
      } catch (err) {
        console.error('Failed to add domain:', err);
      }
    } else {
      // Редактирование домена
      try {
        await apiWrapper.post(
          `/api/domains/${domainData.id}`,
          { ...domainData },
          { headers: { Authorization: token } }
        );
        setDomainDialogOpen(false);
        fetchAssignedDomains();
        fetchUnassignedDomains();
      } catch (err) {
        console.error('Failed to edit domain:', err);
      }
    }
  };

  // ---------------------------------------------------------------------------------
  //                         DELETE DOMAIN
  // ---------------------------------------------------------------------------------
  const handleOpenDeleteDomain = (domain) => {
    setDomainInFocus(domain);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDomain = async (domainId) => {
    try {
      await apiWrapper.delete(`/api/domains/${domainId}`, {
        headers: { Authorization: token }
      });
      setDeleteDialogOpen(false);
      setDomainInFocus(null);
      fetchAssignedDomains();
      fetchUnassignedDomains();
    } catch (err) {
      console.error('Failed to delete domain:', err);
    }
  };

  // ---------------------------------------------------------------------------------
  //                          CHANGE DNS
  // ---------------------------------------------------------------------------------
  const handleOpenChangeDNS = (domain) => {
    setDomainInFocus(domain);
    setChangeDNSOpen(true);
  };

  // ---------------------------------------------------------------------------------
  //                          DOMAIN TRANSFER
  // ---------------------------------------------------------------------------------
  const handleOpenTransferDialog = (domain) => {
    setDomainInFocus(domain);
    setTransferDialogOpen(true);
  };

  const handleTransferSuccess = () => {
    setTransferDialogOpen(false);
    setDomainInFocus(null);
    fetchAssignedDomains();
    fetchUnassignedDomains();
    onProjectChanged && onProjectChanged(); // Перезагрузить список проектов если надо
  };

  // ---------------------------------------------------------------------------------
  //                       DOMAIN TRANSFER HISTORY
  // ---------------------------------------------------------------------------------
  const handleOpenHistoryDialog = (domain) => {
    setDomainInFocus(domain);
    setHistoryDialogOpen(true);
  };

  // ---------------------------------------------------------------------------------
  //                           DOMAIN REDIRECT
  // ---------------------------------------------------------------------------------
  const handleOpenRedirect = (domain) => {
    setDomainInFocus(domain);
    setRedirectModalOpen(true);
  };

  // ---------------------------------------------------------------------------------
  //                          PAGE RULE
  // ---------------------------------------------------------------------------------
  const handleOpenPageRule = (domain) => {
    setDomainInFocus(domain);
    setPageRuleOpen(true);
  };

  // ---------------------------------------------------------------------------------
  //                         AUTO TRANSFER
  // ---------------------------------------------------------------------------------
  const handleAutoTransfer = async () => {
    try {
      await apiWrapper.post(
        `/api/trigger_auto_transfer`,
        { project: project.id },
        { headers: { Authorization: token } }
      );
      // Оповестить пользователя Snackbar-ом или refetch доменов
      fetchAssignedDomains();
      fetchUnassignedDomains();
    } catch (error) {
      console.error('Failed to start auto transfer:', error);
    }
  };

  // ---------------------------------------------------------------------------------
  //                          RENDER TABLES
  // ---------------------------------------------------------------------------------
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderStatusIcon = (domain) => {
    const isOlderThan24Hours = domain.last_http_check_at
      ? differenceInHours(new Date(), parseISO(domain.last_http_check_at)) > 24
      : false;

    if (domain.http_status_code >= 200 && domain.http_status_code <= 299) {
      return <CheckCircleIcon color="success" fontSize="small" />;
    } else if (isOlderThan24Hours) {
      return <InfoIcon color="warning" fontSize="small" />;
    } else if (domain.http_status_code) {
      return <RemoveCircleIcon color="error" fontSize="small" />;
    }
    return <span>-</span>;
  };

  const renderAssignedDomainsTable = () => {
    return (
        <Box marginBottom={4} sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            Домены
            <Tooltip title="Добавить сайт в проект">
              <IconButton
                  color="primary"
                  size="small"
                  onClick={handleOpenAddDomain}
                  sx={{ marginLeft: 1 }}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title="Запустить автоперенос">
              <IconButton
                  color="primary"
                  size="small"
                  onClick={handleAutoTransfer}
                  sx={{ marginLeft: 1 }}
              >
                <MoveUp />
              </IconButton>
            </Tooltip>
          </Typography>

          {isMobile ? (
              // If it's mobile, render a grid of cards
              <Grid container spacing={2}>
                {assignedDomains.map((domain) => (
                    <Grid item xs={12} key={domain.id}>
                      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6">{domain.domain_name}</Typography>
                          <Typography variant="body2">Тип: {domain.type === 'main' ? 'Основной' : domain.type === 'transfer' ? 'Резервный' : domain.type}</Typography>
                          <Typography variant="body2">Режим: {domain.transfer_type === 'auto' ? (
                              <Tooltip title="Автоматически">
                                <HdrAutoIcon fontSize="small" color="primary" />
                              </Tooltip>
                          ) : domain.transfer_type === 'manual' ? (
                              <Tooltip title="Вручную">
                                <BackHandIcon fontSize="small" color="warning" />
                              </Tooltip>
                          ) : (
                              <span>{domain.transfer_type}</span>
                          )}</Typography>
                          <Typography variant="body2">РКН:
                            {domain.status === 'available' ? (
                                <Tooltip title="Доступен">
                                  <CheckCircleIcon color="success" fontSize="small" />
                                </Tooltip>
                            ) : domain.status === 'blocked' ? (
                                <Tooltip title="Заблокирован">
                                  <RemoveCircleIcon color="error" fontSize="small" />
                                </Tooltip>
                            ) : (
                                <span>{domain.status}</span>
                            )}
                          </Typography>
                          <Typography variant="body2">
                            Код ответа: <strong>{domain.http_status_code}</strong>
                          </Typography>
                          <Typography variant="body2">
                            Последняя проверка:{' '}
                            {domain.last_http_check_at
                                ? new Date(domain.last_http_check_at).toLocaleString()
                                : '—'}
                          </Typography>
                          <Typography variant="body2">Сервер: {domain.server_name || '—'}</Typography>
                          <Typography variant="body2">Cloudflare: {domain.cloudflare_account_name || '—'}</Typography>

                          {/* Action buttons */}
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                            <Tooltip title="Редактировать">
                              <IconButton size="small" color="primary" onClick={() => handleOpenEditDomain(domain)}>
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Перенести">
                              <IconButton size="small" color="primary" onClick={() => handleOpenTransferDialog(domain)}>
                                <TransferWithinAStation fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="История переездов">
                              <IconButton size="small" color="primary" onClick={() => handleOpenHistoryDialog(domain)}>
                                <History fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            {
                                domain.cloudflare_account_name &&
                                <Tooltip title="Изменить DNS">
                                  <IconButton size="small" color="primary" onClick={() => handleOpenChangeDNS(domain)}>
                                    <ChangeCircleOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Посмотреть редиректы">
                              <IconButton size="small" color="primary" onClick={() => handleOpenRedirect(domain)}>
                                <ShuffleIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Подклейка доменов (Page Rule)">
                              <IconButton size="small" color="primary" onClick={() => handleOpenPageRule(domain)}>
                                <PlaylistAddIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Удалить домен">
                              <IconButton size="small" color="secondary" onClick={() => handleOpenDeleteDomain(domain)}>
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                ))}
              </Grid>
          ) : (
              <Table size="small" stickyHeader padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell>Домен</TableCell>
                    <TableCell align="center">Режим</TableCell>
                    <TableCell align="center">Тип</TableCell>
                    <TableCell align="center">РКН</TableCell>
                    <TableCell align="center">Статус</TableCell>
                    <TableCell align="center">Сервер</TableCell>
                    <TableCell align="center">Cloudflare</TableCell>
                    <TableCell align="center">Действия</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignedDomains.map((domain) => (
                      <TableRow key={domain.id}>
                        <TableCell>{domain.domain_name}</TableCell>
                        <TableCell align="center">
                          {domain.transfer_type === 'auto' ? (
                              <Tooltip title="Автоматически">
                                <HdrAutoIcon fontSize="small" color="primary" />
                              </Tooltip>
                          ) : domain.transfer_type === 'manual' ? (
                              <Tooltip title="Вручную">
                                <BackHandIcon fontSize="small" color="warning" />
                              </Tooltip>
                          ) : (
                              <span>{domain.transfer_type}</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={domain.type}>
                            <InfoIcon
                                fontSize="small"
                                color={domain.type === 'main' ? 'primary' : 'warning'}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {domain.status === 'available' ? (
                              <Tooltip title="Доступен">
                                <CheckCircleIcon color="success" fontSize="small" />
                              </Tooltip>
                          ) : domain.status === 'blocked' ? (
                              <Tooltip title="Заблокирован">
                                <RemoveCircleIcon color="error" fontSize="small" />
                              </Tooltip>
                          ) : (
                              <span>{domain.status}</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                              title={
                                <>
                                  <Typography variant="caption" display="block">
                                    Код ответа: <strong>{domain.http_status_code}</strong>
                                  </Typography>
                                  <Typography variant="caption" display="block">
                                    Последняя проверка:{' '}
                                    {domain.last_http_check_at
                                        ? new Date(domain.last_http_check_at).toLocaleString()
                                        : '—'}
                                  </Typography>
                                </>
                              }
                          >
                            {renderStatusIcon(domain)}
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">{domain.server_name || '—'}</TableCell>
                        <TableCell align="center">{domain.cloudflare_account_name || '—'}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Редактировать">
                            <IconButton size="small" color="primary" onClick={() => handleOpenEditDomain(domain)}>
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Перенести">
                            <IconButton size="small" color="primary" onClick={() => handleOpenTransferDialog(domain)}>
                              <TransferWithinAStation fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="История переездов">
                            <IconButton size="small" color="primary" onClick={() => handleOpenHistoryDialog(domain)}>
                              <History fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {
                              domain.cloudflare_account_name &&
                              <Tooltip title="Изменить DNS">
                                <IconButton size="small" color="primary" onClick={() => handleOpenChangeDNS(domain)}>
                                  <ChangeCircleOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                          }
                          <Tooltip title="Посмотреть редиректы">
                            <IconButton size="small" color="primary" onClick={() => handleOpenRedirect(domain)}>
                              <ShuffleIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Подклейка доменов (Page Rule)">
                            <IconButton size="small" color="primary" onClick={() => handleOpenPageRule(domain)}>
                              <PlaylistAddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Удалить домен">
                            <IconButton size="small" color="secondary" onClick={() => handleOpenDeleteDomain(domain)}>
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
          )}
        </Box>
    );
  };

  const renderUnassignedDomainsTable = () => {
    return (
        <Box sx={{ marginBottom: 4, width: '100%' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            Резервные домены
            <Tooltip title="Добавить домен в проект">
              <IconButton
                  color="primary"
                  size="small"
                  onClick={handleOpenAddDomain}
                  sx={{ marginLeft: 1 }}
              >
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>

          {isMobile ? (
              <Grid container spacing={2}>
                {unassignedDomains.map((domain) => (
                    <Grid item xs={12} key={domain.id}>
                      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6">{domain.domain_name}</Typography>
                          <Typography variant="body2">Cloudflare Профиль: {domain.cloudflare_account_name || '—'}</Typography>

                          {/* Action buttons */}
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                            <Tooltip title="Редактировать">
                              <IconButton size="small" color="primary" onClick={() => handleOpenEditDomain(domain)}>
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            {
                                domain.cloudflare_account_name &&
                                  <Tooltip title="Изменить DNS">
                                    <IconButton size="small" color="primary" onClick={() => handleOpenChangeDNS(domain)}>
                                      <ChangeCircleOutlinedIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                            }
                            <Tooltip title="Удалить домен">
                              <IconButton size="small" color="secondary" onClick={() => handleOpenDeleteDomain(domain)}>
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                ))}
              </Grid>
          ) : (
              <Table size="small" stickyHeader padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell>Домен</TableCell>
                    <TableCell>Cloudflare Профиль</TableCell>
                    <TableCell>Действия</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unassignedDomains.map((domain) => (
                      <TableRow key={domain.id}>
                        <TableCell>{domain.domain_name}</TableCell>
                        <TableCell>{domain.cloudflare_account_name || '—'}</TableCell>
                        <TableCell>
                          <Tooltip title="Редактировать">
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleOpenEditDomain(domain)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {
                            domain.cloudflare_account_name && <Tooltip title="Изменить DNS">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => handleOpenChangeDNS(domain)}
                                >
                                  <ChangeCircleOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                          }
                          <Tooltip title="Удалить домен">
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => handleOpenDeleteDomain(domain)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
          )}
        </Box>
    );
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Box display="flex" mb={2}>
        <Typography variant="h5">
          {project.name}
        </Typography>
        <Box ml="auto">
          <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                editProject(project);
              }}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                deleteProject(project.id);
              }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {renderAssignedDomainsTable()}
      {renderUnassignedDomainsTable()}

      {/* Диалог Add/Edit Domain */}
      <DomainDialog
        open={domainDialogOpen}
        isEditMode={isEditMode}
        domainData={domainInFocus}
        servers={servers}
        cloudflareAccounts={cloudflareAccounts}
        onClose={() => {
          setDomainDialogOpen(false);
          setDomainInFocus(null);
        }}
        onSubmit={handleSubmitDomain}
        token={token}
      />

      {/* Диалог Delete Domain */}
      <DeleteDomainDialog
        open={deleteDialogOpen}
        domain={domainInFocus}
        onClose={() => {
          setDeleteDialogOpen(false);
          setDomainInFocus(null);
        }}
        onDeleteDomain={handleDeleteDomain}
      />

      {/* Диалог Change DNS */}
      <ChangeDNSDialog
        open={changeDNSOpen}
        onClose={() => {
          setChangeDNSOpen(false);
          setDomainInFocus(null);
        }}
        token={token}
        domain={domainInFocus}
      />

      {/* Transfer Dialog */}
      <DomainTransferDialog
        open={transferDialogOpen}
        onClose={() => {
          setTransferDialogOpen(false);
          setDomainInFocus(null);
        }}
        token={token}
        sourceDomain={domainInFocus}
        onTransfer={handleTransferSuccess}
        project={project}
      />

      {/* Transfer History */}
      <DomainTransferHistoryDialog
        open={historyDialogOpen}
        onClose={() => {
          setHistoryDialogOpen(false);
          setDomainInFocus(null);
        }}
        token={token}
        domain={domainInFocus}
      />

      {/* Redirect Modal */}
      <DomainRedirectModal
        open={redirectModalOpen}
        onClose={() => {
          setRedirectModalOpen(false);
          setDomainInFocus(null);
        }}
        token={token}
        domain={domainInFocus}
      />

      {/* Page Rule Dialog */}
      <PageRuleDialog
        open={pageRuleOpen}
        onClose={() => {
          setPageRuleOpen(false);
          setDomainInFocus(null);
        }}
        token={token}
        domain={domainInFocus}
      />
    </Paper>
  );
};

export default ProjectDomains;