import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    DialogContentText
} from '@mui/material';
import apiWrapper from "../../apiConfig";
import Autocomplete from "@mui/material/Autocomplete";

const DomainDialog = ({
    open,
    isEditMode,
    domainData,
    servers,
    cloudflareAccounts,
    onClose,
    onSubmit,
    token
}) => {
    const [domain, setDomain] = useState({
        domain_name: '',
        file_path: '',
        type: 'main',
        transfer_type: 'auto',
        server_id: '',
        cloudflare_account_id: '',
        project_id: '',
        registrars_id: '',
    });

    const [registrars, setRegistrars] = useState([]);

    useEffect(() => {
        if (open && domainData) {
            setDomain(domainData);
        }
    }, [open, domainData]);

    useEffect(() => {
        if (open) {
            fetchRegistrars();
        }
    }, [open]);

    // Запрос списка регистраторов
    const fetchRegistrars = async () => {
        try {
            const response = await apiWrapper.get(`/api/registrars`, {
                headers: { Authorization: token },
            });
            console.log(response);
            setRegistrars(response.data.data || []);
        } catch (error) {
            console.error("Ошибка при загрузке списка регистраторов:", error);
        }
    };

    const handleDomainChange = (field, value) => {
        let nextState = { ...domain, [field]: value };
        if (!isEditMode && field === 'domain_name') {
            nextState.file_path = `/var/www/www-root/data/www/${value}`;
        }
        setDomain(nextState);
    };

    const handleSubmit = () => {
        onSubmit(domain);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{isEditMode ? 'Редактировать домен' : 'Добавить домен'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isEditMode ? 'Отредактируйте данные домена' : 'Добавьте новый домен в проект'}
                </DialogContentText>

                <TextField
                    label="Домен"
                    margin="normal"
                    fullWidth
                    value={domain.domain_name || ''}
                    onChange={(e) => handleDomainChange('domain_name', e.target.value)}
                />

                <TextField
                    label="Путь"
                    margin="normal"
                    fullWidth
                    value={domain.file_path || ''}
                    onChange={(e) => handleDomainChange('file_path', e.target.value)}
                />

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Тип</InputLabel>
                    <Select
                        value={domain.type || 'main'}
                        onChange={(e) => handleDomainChange('type', e.target.value)}
                        variant={'outlined'}
                    >
                        <MenuItem value="main">Main</MenuItem>
                        <MenuItem value="transfer">Transfer</MenuItem>
                    </Select>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Режим</InputLabel>
                    <Select
                        value={domain.transfer_type || 'auto'}
                        onChange={(e) => handleDomainChange('transfer_type', e.target.value)}
                        variant={'outlined'}
                    >
                        <MenuItem value="auto">Автоматический</MenuItem>
                        <MenuItem value="manual">Ручной</MenuItem>
                    </Select>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Сервер</InputLabel>
                    <Select
                        value={domain.server_id || ''}
                        onChange={(e) => handleDomainChange('server_id', e.target.value)}
                        variant={'outlined'}
                    >
                        {servers.map((srv) => (
                            <MenuItem key={srv.id} value={srv.id}>
                                {srv.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Autocomplete
                    options={[{ id: '', name: 'Нет' }, ...cloudflareAccounts]}
                    getOptionLabel={(option) => option.name}
                    value={
                        cloudflareAccounts.find((cf) => cf.id === domain.cloudflare_account_id) || null
                    }
                    onChange={(event, newValue) => {
                        handleDomainChange('cloudflare_account_id', newValue ? newValue.id : '');
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (event && event.type === 'change') {
                            handleDomainChange('cloudflare_account_id', newInputValue || '');
                        }
                    }}
                    onBlur={() => {
                        if (!domain.cloudflare_account_id) {
                            handleDomainChange('cloudflare_account_id', '');
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Cloudflare Account"
                            margin="normal"
                            fullWidth
                        />
                    )}
                />

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Регистратор</InputLabel>
                    <Select
                        value={domain.registrars_id || ''}
                        onChange={(e) => handleDomainChange('registrars_id', e.target.value)}
                        variant={'outlined'}
                    >
                        <MenuItem value="">Не выбрано</MenuItem>
                        {registrars.map((reg) => (
                            <MenuItem key={reg.id} value={reg.id}>
                                {reg.name} ({reg.type})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit} variant="contained">
                    {isEditMode ? 'Сохранить' : 'Добавить'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DomainDialog;