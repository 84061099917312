import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Delete, Edit, List } from "@mui/icons-material";
import apiWrapper from "../../../apiConfig";
import RegistrarDomainListDialog from "../../dialogs/RegistrarDomainListDialog";
import RegistrarFormDialog from "../../dialogs/RegistrarFormDialog";



const initialRegistrarState = {
    type: "reghouse.ru", // Значение по умолчанию
    name: "",
    login: "",
    password: "",
};

const Registrars = ({ token }) => {
    const [registrars, setRegistrars] = useState([]);
    const [error, setError] = useState("");
    const [dialogState, setDialogState] = useState({
        open: false,
        isEditing: false,
        registrarData: initialRegistrarState,
    });
    const [domainsDialogState, setDomainsDialogState] = useState({
        open: false,
        registrarId: null,
    });

    const fetchRegistrars = useCallback(async () => {
        try {
            const { data } = await apiWrapper.get(`/api/registrars`, {
                headers: { Authorization: token },
            });
            setRegistrars(data.data);
        } catch {
            setError("Не удалось загрузить список регистраторов");
        }
    }, [token]);

    useEffect(() => {
        fetchRegistrars();
    }, [fetchRegistrars]);

    const handleRegistrarDialogOpen = (isEditing = false, registrar = initialRegistrarState) => {
        setDialogState({ open: true, isEditing, registrarData: registrar });
    };

    const handleRegistrarDialogClose = () => {
        setDialogState({ open: false, isEditing: false, registrarData: initialRegistrarState });
    };

    const handleDomainDialogOpen = (registrarId) => {
        setDomainsDialogState({ open: true, registrarId });
    };

    const handleDomainDialogClose = () => {
        setDomainsDialogState({ open: false, registrarId: null });
    };

    const handleDeleteRegistrar = async (id) => {
        try {
            await apiWrapper.delete(`/api/registrars/${id}`, {
                headers: { Authorization: token },
            });
            fetchRegistrars();
        } catch {
            setError("Не удалось удалить регистратора");
        }
    };

    return (
        <Container>
            {error && <Typography color="error">{error}</Typography>}
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRegistrarDialogOpen()}
                >
                    Добавить регистратора
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Тип</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Логин</TableCell>
                        <TableCell>Пароль</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {registrars.map((registrar) => (
                        <TableRow key={registrar.id}>
                            <TableCell>{registrar.type}</TableCell>
                            <TableCell>{registrar.name}</TableCell>
                            <TableCell>{registrar.login}</TableCell>
                            <TableCell>{registrar.password}</TableCell>
                            <TableCell>
                                <IconButton
                                    color="primary"
                                    onClick={() => handleRegistrarDialogOpen(true, registrar)}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleDeleteRegistrar(registrar.id)}
                                >
                                    <Delete />
                                </IconButton>
                                <IconButton
                                    color="info"
                                    onClick={() => handleDomainDialogOpen(registrar.id)}
                                >
                                    <List />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <RegistrarFormDialog
                open={dialogState.open}
                isEditing={dialogState.isEditing}
                registrarData={dialogState.registrarData}
                onClose={handleRegistrarDialogClose}
                fetchRegistrars={fetchRegistrars}
                token={token}
            />
            <RegistrarDomainListDialog
                open={domainsDialogState.open}
                registrarId={domainsDialogState.registrarId}
                onClose={handleDomainDialogClose}
                token={token}
            />
        </Container>
    );
};

export default Registrars;