import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Chip, Divider, Container, Grid } from '@mui/material';
import apiWrapper from '../../../apiConfig';

const CheckSite = ({ token }) => {
    const [domainInput, setDomainInput] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [domainsResult, setDomainsResult] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCheckDomains = async () => {
        const domainsArray = domainInput
            .split(/[\n,]+/) // Разделяем по запятым и новой строке
            .map(domain => domain.trim()) // Убираем лишние пробелы
            .filter(domain => domain); // Исключаем пустые строки

        const formattedDomains = domainsArray.map(domain => ({ domain }));

        setLoading(true);
        setResponseMessage('');
        setDomainsResult([]);

        try {
            const response = await apiWrapper.post(`/api/check_domains`, {
                domains: formattedDomains
            }, {
                headers: { Authorization: token }
            });

            console.log('Server response:', response.data);

            if (response.data && response.data.foundDomains && response.data.foundDomains.allDomains) {
                setDomainsResult(response.data.foundDomains.allDomains);
                setResponseMessage('Проверка доменов выполнена успешно');
            } else {
                setResponseMessage('Не удалось получить результаты');
            }
        } catch (error) {
            console.error('Ошибка запроса:', error);
            setResponseMessage('Ошибка при проверке доменов');
        } finally {
            setLoading(false);
        }
    };

    const getStatusChipColor = (status) => {
        if (status === 'blocked') return 'error';
        if (status === 'free') return 'success';
        return 'default';
    };

    return (
        <Container>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <TextField
                        label="Введите домены через запятую или с новой строки"
                        multiline
                        rows={4} // Увеличиваем высоту текстового поля
                        fullWidth
                        value={domainInput}
                        onChange={(e) => setDomainInput(e.target.value)}
                        margin="normal"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCheckDomains}
                        disabled={loading}
                        sx={{ width: 'auto' }}
                    >
                        {loading ? 'Проверка...' : 'Проверить'}
                    </Button>
                </Grid>
            </Grid>

            {responseMessage && (
                <Typography color="primary" mt={2} textAlign="center">
                    {responseMessage}
                </Typography>
            )}

            {domainsResult.length > 0 && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom textAlign="center">
                        Результаты проверки доменов:
                    </Typography>
                    <Box mt={2}>
                        {domainsResult.map((domain, index) => (
                            <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                <Typography>{domain.domain}</Typography>
                                <Chip
                                    label={domain.status === 'blocked' ? 'Заблокирован' : 'Свободен'}
                                    color={getStatusChipColor(domain.status)}
                                    size="small"
                                />
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </Container>
    );
};

export default CheckSite;