import React, { useState } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  ButtonGroup,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DropdownButton from '../ui/dropdown';
import ThemeToggle from "../ThemeToggle";

const Dashboard = ({ token, setToken }) => {
  const user = jwtDecode(token);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is small

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    navigate('/login');
  };

  const isSelected = (path) => location.pathname === path;

  const buttonStyle = (path) => ({
    backgroundColor: isSelected(path) ? '#2F3235' : 'inherit',
    borderRadius: isSelected(path) ? '4px' : '0px',
    color: isSelected(path) ? '#fff' : 'inherit',
  });

  const sitesMenuItems = [
    { label: 'Проекты', path: '/projects' },
    { label: 'Все сайты', path: '/sites' },
  ];
  const toolsMenuItems = [
    { label: 'Добавление в Cloudflare', path: '/dns' },
    { label: 'Проверка доменов', path: '/check-site' },
  ];
  const accMenuItem = [
    { label: 'Cloudflare', path: '/cloudflare' },
    { label: 'Серверы', path: '/servers' },
    { label: 'Регистраторы', path: '/registrars' },
  ];
  const adminMenuItem = [
    { label: 'Создать Юзера', path: '/admin' },
    { label: 'Таблицы', path: '/tables' },
  ];

  // Handle opening the menu for mobile
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  // Handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              <Button component={Link} to="/" sx={buttonStyle('/')}>Главная</Button>
            </Typography>
            <Typography variant="h6">
              <ThemeToggle/>
            </Typography>

            {/* Для мобильных устройств отображаем кнопку меню */}
            {isMobile ? (
                <>
                  {/* Иконка меню */}
                  <IconButton
                      edge="end"
                      color="inherit"
                      onClick={handleMenuClick}
                      style={{ marginLeft: 'auto' }} // Отодвигаем иконку вправо
                  >
                    <MenuIcon />
                  </IconButton>

                  {/* Мобильное меню */}
                  <Menu
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          width: '250px', // Ширина меню
                          padding: '8px', // Отступы
                          maxHeight: '400px', // Максимальная высота меню
                          overflowY: 'auto', // Добавляем вертикальный скролл
                        },
                      }}
                  >
                    <div>
                      {/* Группа "Сайты" */}
                      <MenuItem disabled>
                        <Typography variant="subtitle1" color="textSecondary">Сайты</Typography>
                      </MenuItem>
                      {sitesMenuItems.map((item) => (
                          <MenuItem key={item.path} component={Link} to={item.path} onClick={handleMenuClose}>
                            {item.label}
                          </MenuItem>
                      ))}

                      <Divider />

                      {/* Группа "Инструменты" */}
                      <MenuItem disabled>
                        <Typography variant="subtitle1" color="textSecondary">Инструменты</Typography>
                      </MenuItem>
                      {toolsMenuItems.map((item) => (
                          <MenuItem key={item.path} component={Link} to={item.path} onClick={handleMenuClose}>
                            {item.label}
                          </MenuItem>
                      ))}

                      <Divider />

                      {/* Группа "Аккаунты" */}
                      <MenuItem disabled>
                        <Typography variant="subtitle1" color="textSecondary">Аккаунты</Typography>
                      </MenuItem>
                      {accMenuItem.map((item) => (
                          <MenuItem key={item.path} component={Link} to={item.path} onClick={handleMenuClose}>
                            {item.label}
                          </MenuItem>
                      ))}

                      {/* Админка для пользователя с ролью admin */}
                      {user.role === 'admin' && (
                          <>
                            <Divider />
                            <MenuItem disabled>
                              <Typography variant="subtitle1" color="textSecondary">Админка</Typography>
                            </MenuItem>
                            {adminMenuItem.map((item) => (
                                <MenuItem key={item.path} component={Link} to={item.path} onClick={handleMenuClose}>
                                  {item.label}
                                </MenuItem>
                            ))}
                          </>
                      )}

                      <Divider />
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </div>
                  </Menu>
                </>
            ) : (
                // Для десктопных устройств показываем кнопки в горизонтальном ряду
                <ButtonGroup variant="text" color="inherit">
                  <DropdownButton
                      title="Сайты"
                      menuItems={sitesMenuItems}
                      buttonStyle={buttonStyle}
                  />
                  <DropdownButton
                      title="Инструменты"
                      menuItems={toolsMenuItems}
                      buttonStyle={buttonStyle}
                  />
                  <DropdownButton
                      title="Аккаунты"
                      menuItems={accMenuItem}
                      buttonStyle={buttonStyle}
                  />
                  {user.role === 'admin' && (
                      <DropdownButton
                          title="Админка"
                          menuItems={adminMenuItem}
                          buttonStyle={buttonStyle}
                      />
                  )}
                  {/* Кнопка Логаута для десктопа */}
                  <Button color="inherit" onClick={handleLogout}>
                    Logout
                  </Button>
                </ButtonGroup>
            )}
          </Toolbar>
        </AppBar>

        {/* Основной контент */}
        <Box p={2}>
          <Outlet />
        </Box>
      </div>
  );
};

export default Dashboard;