import React, { useState } from 'react';
import { IconButton, Popover, Typography, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeletePopover = ({ onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmDelete = () => {
        onDelete();
        handleClose();
    };

    return (
        <>
            <IconButton onClick={handleClick} color="error">
                <DeleteIcon />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography>Вы уверены, что хотите удалить?</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleClose} color="secondary">
                            Нет
                        </Button>
                        <Button onClick={handleConfirmDelete} color="error" variant="contained">
                            Да
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

export default DeletePopover;