import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Button, FormControl, InputLabel, Select, MenuItem, Snackbar
} from '@mui/material';
import apiWrapper from './../../apiConfig';

const DomainTransferDialog = ({ open, onClose, onTransfer, token, sourceDomain, project }) => {
    const [transferDomains, setTransferDomains] = useState([]);
    const [targetDomainId, setTargetDomainId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        const fetchTransferDomains = async () => {
            try {
                const response = await apiWrapper.get(`/api/domains`, {
                    headers: { Authorization: token }
                });
                setTransferDomains(
                    response.data.data.filter(domain => domain.type === 'transfer' && domain.project_id === project.id)
                );
            } catch (error) {
                console.error('Failed to fetch transfer domains', error);
            }
        };

        if (open) {
            fetchTransferDomains();
        }
    }, [token, open]);

    const handleTransfer = async () => {
        if (targetDomainId && sourceDomain) {
            try {
                await apiWrapper.post(`/api/transfer_explicit`, {
                    sourceDomainId: sourceDomain.id,
                    targetDomainId,
                }, {
                    headers: { Authorization: token }
                });
                setSnackbarOpen(true);
                if(onTransfer) onTransfer();
                onClose();
            } catch (error) {
                console.error('Failed to transfer domain', error);
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Transfer Domain</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a target domain to transfer the source domain to.
                    </DialogContentText>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Target Domain</InputLabel>
                        <Select
                            value={targetDomainId}
                            onChange={(e) => setTargetDomainId(e.target.value)}
                            variant={'outlined'}
                        >
                            {transferDomains.map((domain) => (
                                <MenuItem key={domain.id} value={domain.id}>
                                    {domain.domain_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleTransfer} color="primary">
                        Transfer
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message="Transfer process started"
                autoHideDuration={6000}
            />
        </>
    );
};

export default DomainTransferDialog;
