import axios from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const API_BASE_URL = '';

const apiWrapper = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
});

// Глобальный перехватчик ошибок
apiWrapper.interceptors.response.use(
    response => response, // Пропускаем успешные запросы
    error => {
        if (error.response) {
            const { status, data } = error.response;

            if (
                (status === 401 && data.error === 'No token provided')
                ||
                (status === 403 && data.error === 'Failed to authenticate token')
            ) {
                console.warn('Сессия истекла, редирект на логин...');

                localStorage.removeItem('token');

                history.push('/login');
                window.location.reload();
            }
        }

        return Promise.reject(error);
    }
);

export default apiWrapper;
