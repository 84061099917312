import React, { useEffect, useState } from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
    Box, Collapse, IconButton, Tooltip, useTheme, useMediaQuery
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import apiWrapper from '../../../apiConfig';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InfoIcon from "@mui/icons-material/Info";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import BackHandIcon from "@mui/icons-material/BackHand";
import { differenceInHours, parseISO } from "date-fns";

const Sites = ({ token, label = null, status = 'all', open = true }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // Определение мобильных устройств

    const [domains, setDomains] = useState([]);
    const [error, setError] = useState('');
    const [openDomains, setOpenDomains] = useState(open);

    const availableStatus = ['all', 'active', 'blocked', 'broken', 'expired'];

    if (!availableStatus.includes(status)) {
        status = 'all';
    }

    const isExpiredOrToday = (expiredAt) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const expirationDate = new Date(expiredAt);
        expirationDate.setHours(0, 0, 0, 0);
        return expirationDate <= today;
    };


    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await apiWrapper.get(`/api/user_domains`, {
                    headers: { Authorization: token },
                    params: { status },
                });
                setDomains(response.data.data);
                if(open === true && response.data.data.length === 0) {
                    setOpenDomains(false);
                }
            } catch (error) {
                setError('Failed to fetch domains');
            }
        };

        fetchDomains();
    }, [token, status]);

    return (
        <Container>
            {error && <Typography color="error">{error}</Typography>}

            <Box mt={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" component="h2">
                        {label ? label : "Список доменов"}
                    </Typography>
                    <IconButton onClick={() => setOpenDomains(!openDomains)}>
                        {openDomains ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                <Collapse in={openDomains}>
                    {isMobile ? (
                        <Box display="flex" flexDirection="column" gap={2}>
                            {domains.map((domain) => (
                                <Paper key={`${domain.id}-${Math.random()}`} sx={{ padding: 2 }}>
                                    <Typography variant="h6">{domain.domain_name}</Typography>
                                    <Typography variant="body2">Проект: {domain.project_name || 'No Project'}</Typography>
                                    <Typography variant="body2">Режим: {domain.transfer_type === 'auto' ?
                                        "Автоматически"
                                        :
                                        domain.transfer_type === 'manual' ?
                                            "Вручную"
                                            :
                                            domain.transfer_type
                                    }</Typography>
                                    <Typography variant="body2">Тип: {domain.type}</Typography>
                                    <Typography variant="body2">
                                        РКН:
                                        {domain.status === 'available' ?
                                            <CheckCircleIcon fontSize="small" color="success" /> :
                                            domain.status === 'blocked' ?
                                                <RemoveCircleIcon fontSize="small" color="error" /> :
                                                domain.status
                                        }
                                    </Typography>
                                    <Typography variant="body2">
                                        Код ответа: <strong>{domain.http_status_code}</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                        Последняя проверка: {(domain.last_http_check_at) ? new Date(domain.last_http_check_at).toLocaleString() : '-'}
                                    </Typography>
                                    <Typography variant="body2">
                                        Дата добавления: {new Date(domain.created_at).toLocaleDateString()}
                                    </Typography>
                                    {status === 'expired' && (
                                        <Typography
                                            variant="body2"
                                            sx={(domain) => ({
                                                color: isExpiredOrToday(domain.expired_at) ? 'error.main' : 'inherit'
                                            })}
                                        >
                                        Дата окончания: {new Date(domain.expired_at).toLocaleDateString('ru-RU')}
                                        <br/>
                                        Регистратор домена: {domain.registrar_type}
                                    </Typography>)
                                    }
                                </Paper>
                            ))}
                        </Box>
                    ) : (
                        <Paper>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Домен</TableCell>
                                        <TableCell>Проект</TableCell>
                                        <TableCell align="center">Режим</TableCell>
                                        <TableCell align="center">Тип</TableCell>
                                        <TableCell align="center">РКН</TableCell>
                                        <TableCell align="center">Статус</TableCell>
                                        <TableCell align="center">Дата добавления</TableCell>
                                        {status === 'expired' && (
                                            <TableCell align="center">Дата окончания</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {domains.map((domain) => (
                                        <TableRow key={`${domain.id}-${Math.random()}`}>
                                            <TableCell>{domain.domain_name}</TableCell>
                                            <TableCell>{domain.project_name || 'No Project'}</TableCell>
                                            <TableCell align="center">
                                                {domain.transfer_type === 'auto' ?
                                                    <Tooltip title="Автоматически">
                                                        <HdrAutoIcon fontSize="small" color="primary" />
                                                    </Tooltip>
                                                    :
                                                    domain.transfer_type === 'manual' ?
                                                        <Tooltip title="Вручную">
                                                            <BackHandIcon fontSize="small" color="warning" />
                                                        </Tooltip>
                                                        :
                                                        domain.transfer_type
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title={domain.type}>
                                                    <InfoIcon fontSize="small" color={domain.type === 'main' ? "primary" : "warning"} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                {domain.status === 'available' ?
                                                    <Tooltip title="Доступен">
                                                        <CheckCircleIcon fontSize="small" color="success" />
                                                    </Tooltip>
                                                    :
                                                    domain.status === 'blocked' ?
                                                        <Tooltip title="Заблокирован">
                                                            <RemoveCircleIcon fontSize="small" color="error" />
                                                        </Tooltip>
                                                        :
                                                        domain.status
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title={
                                                    <div>
                                                        <Typography variant="caption" component="div" style={{ marginTop: 4 }}>
                                                            Код ответа: <strong>{domain.http_status_code}</strong>
                                                        </Typography>
                                                        <Typography variant="caption" component="div">
                                                            Последняя проверка: {new Date(domain.last_http_check_at).toLocaleString()}
                                                        </Typography>
                                                    </div>
                                                }>
                                                    {(() => {
                                                        const isOlderThan24Hours = domain.last_http_check_at
                                                            ? differenceInHours(new Date(), parseISO(domain.last_http_check_at)) > 24
                                                            : false;

                                                        if (domain.http_status_code >= 200 && domain.http_status_code <= 299) {
                                                            return <CheckCircleIcon fontSize="small" color="success" />;
                                                        } else if (isOlderThan24Hours) {
                                                            return <InfoIcon fontSize="small" color="warning" />;
                                                        } else if (domain.http_status_code) {
                                                            return <RemoveCircleIcon fontSize="small" color="error" />;
                                                        } else {
                                                            return <div>-</div>;
                                                        }
                                                    })()}
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">{new Date(domain.created_at).toLocaleDateString()}</TableCell>
                                            {status === 'expired' && (
                                                <TableCell  align="center" sx={(domain) => ({
                                                    color: isExpiredOrToday(domain.expired_at) ? 'error.main' : 'inherit'
                                                })}>
                                                    {new Date(domain.expired_at).toLocaleDateString('ru-RU')}
                                                    <br/>
                                                    Регистратор домена: {domain.registrar_type}
                                                </TableCell>
                                            )}

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    )}
                </Collapse>
            </Box>
        </Container>
    );
};

export default Sites;