import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Snackbar,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import apiWrapper from '../../../apiConfig';

// Диалоги для проектов
import CreateProjectDialog from './../../dialogs/CreateProjectDialog';
import EditProjectDialog from './../../dialogs/EditProjectDialog';

// Наш компонент с таблицами доменов
import ProjectDomains from './ProjectDomains';

// Основной компонент
const Projects = ({ token }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    // Состояния для диалогов (проекты)
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [openEditProject, setOpenEditProject] = useState(false);

    // Данные для создания/редактирования проектов
    const [newProjectName, setNewProjectName] = useState('');
    const [editProjectName, setEditProjectName] = useState('');

    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // ---------------------------------------------------------------------------------
    //                                  FETCH PROJECTS
    // ---------------------------------------------------------------------------------
    const fetchProjects = async () => {
        try {
            const response = await apiWrapper.get(`/api/user_projects`, {
                headers: { Authorization: token }
            });
            setProjects(response.data.data);
        } catch (err) {
            setError('Failed to fetch projects');
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleSnackbarClose = () => setSnackbarOpen(false);

    // ---------------------------------------------------------------------------------
    //                                PROJECTS: CREATE
    // ---------------------------------------------------------------------------------
    const handleCreateProject = async () => {
        try {
            await apiWrapper.post(
                `/api/projects`,
                { name: newProjectName },
                { headers: { Authorization: token } }
            );
            setOpenCreateProject(false);
            setNewProjectName('');
            fetchProjects();
        } catch (err) {
            setError('Failed to create projects');
        }
    };

    // ---------------------------------------------------------------------------------
    //                                PROJECTS: EDIT
    // ---------------------------------------------------------------------------------
    const handleOpenEditProjectDialog = (project) => {
        setSelectedProject(project);
        setEditProjectName(project.name);
        setOpenEditProject(true);
    };

    const handleEditProject = async () => {
        if (!selectedProject) return;
        try {
            await apiWrapper.put(
                `/api/projects/${selectedProject.id}`,
                { name: editProjectName },
                { headers: { Authorization: token } }
            );
            setOpenEditProject(false);
            setEditProjectName('');
            setSelectedProject(null);
            fetchProjects();
        } catch (err) {
            setError('Failed to edit projects');
        }
    };

    // ---------------------------------------------------------------------------------
    //                                PROJECTS: DELETE
    // ---------------------------------------------------------------------------------
    const handleDeleteProject = async (projectId) => {
        try {
            await apiWrapper.delete(`/api/projects/${projectId}`, {
                headers: { Authorization: token }
            });
            if (selectedProject && selectedProject.id === projectId) {
                setSelectedProject(null);
            }
            fetchProjects();
        } catch (err) {
            setError('Failed to delete projects');
        }
    };

    // ---------------------------------------------------------------------------------
    //                              UI: SELECT PROJECT
    // ---------------------------------------------------------------------------------
    const handleSelectProject = (project) => {
        setSelectedProject(project);
    };

    return (
        <Container maxWidth="xl">
            {error && <Typography color="error">{error}</Typography>}

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                {/* Сайдбар (список проектов) */}
                <Box
                    width={{ xs: '100%', md: '20%' }}
                    marginBottom={{ xs: 2, md: 0 }}
                    sx={{ overflowY: 'auto', maxHeight: '80vh' }} // добавлен скролл
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        onClick={() => setOpenCreateProject(true)}
                    >
                        Создать проект
                    </Button>
                    <Paper sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableBody>
                                {projects.map((project) => (
                                    <TableRow
                                        key={project.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => handleSelectProject(project)}
                                        selected={selectedProject?.id === project.id}
                                    >
                                        <TableCell>
                                            {project.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>

                {/* Правая часть: Домены выбранного проекта */}
                <Box flex={1} overflow="hidden" sx={{ display: 'flex', flexDirection: 'column' }}>
                    {selectedProject ? (
                        <ProjectDomains
                            token={token}
                            project={selectedProject}
                            onProjectChanged={fetchProjects}
                            editProject={handleOpenEditProjectDialog}
                            deleteProject={handleDeleteProject}
                        />
                    ) : (
                        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center' }}>
                            Выберите проект...
                        </Typography>
                    )}
                </Box>
            </Box>

            {/* Диалоги для проектов */}
            <CreateProjectDialog
                open={openCreateProject}
                onClose={() => setOpenCreateProject(false)}
                newProjectName={newProjectName}
                setNewProjectName={setNewProjectName}
                onCreateProject={handleCreateProject}
            />

            <EditProjectDialog
                open={openEditProject}
                onClose={() => setOpenEditProject(false)}
                editProjectName={editProjectName}
                setEditProjectName={setEditProjectName}
                onEditProject={handleEditProject}
            />

            <Snackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message="Операция успешно выполнена"
                autoHideDuration={4000}
            />
        </Container>
    );
};

export default Projects;